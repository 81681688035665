<template>
  <el-row>
    <el-form label-width="120px">
      <el-row>
        待定
      </el-row>
    </el-form>
  </el-row>
</template>
<script>
export default {
  props: {
    setting: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      dataInfo: {}
    }
  },
  mounted() {},
  methods: {}
}
</script>
